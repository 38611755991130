import React, {createRef} from "react";
import {Navigate} from "react-router-dom"
import './AdminPage.css';
import PopUp from '../../components/PopUp';
import FetchData from '../../components/FetchData';
import SortData from '../../components/SortData';
import FormatTime from '../../components/FormatTime';
import ButtonAdd from '../../components/ButtonAdd';
import {AddScores, MenuMatch, MenuCompetition, MenuSeason, MenuTeam, MenuPlayer, MenuCodes} from '../../components/DataMenu';


class AdminPage extends React.Component {
	constructor(props) {
		super(props);

		this.ref = createRef(null);

		this.state = {
			matches: [],
			competitions: [],
			seasons: [],
			teams: [],
			players: [],
			codes: [],
			season: null,
			addMenu: null,
			redirect: null,
			list: null,
			data: null,
			addItem: null
		};
	}
	componentDidMount() {
		/*this.retrieveMatches();
		this.retrieveCompetitions();
		this.retrievePlayers();
		this.retrieveSeasons();
		this.retrieveTeams();*/

		this.retrieveMatches();
		this.retrieveCompetitions();
		this.retrieveSeasons();
	}
	async retrieveMatches() {
		const data = await FetchData("match");
		if(data.isError) {
			if(data.code === 404)
				return;
			this.props.setErrorMessage(data.message);
			return;
		}
		else {
			this.setState({
				matches: SortData(data.data, "start_datetime")
			});
		}
	}
	async retrieveCompetitions() {
		const data = await FetchData("competition");
		if(data.isError) {
			if(data.code === 404)
				return;
			this.props.setErrorMessage(data.message);
			return;
		}
		else {
			this.setState({
				competitions: SortData(data.data, "name")
			});
		}
	}
	async retrieveCodes() {
		await FetchData("competitioncode", {method: "DELETE"});

		const data = await FetchData("competitioncode");
		if(data.isError) {
			if(data.code === 404)
				return;
			this.props.setErrorMessage(data.message);
			return;
		}
		else {
			this.setState({
				codes: SortData(data.data, "expire_datetime")
			});
		}
	}
	async retrievePlayers() {
		const data = await FetchData("user");
		if(data.isError) {
			if(data.code === 404)
				return;
			this.props.setErrorMessage(data.message);
			return;
		}
		else {
			this.setState({
				players: SortData(data.data, "name")
			});
		}
	}
	async retrieveSeasons() {
		const data = await FetchData("season");
		if(data.isError) {
			if(data.code === 404)
				return;
			this.props.setErrorMessage(data.message);
			return;
		}
		else {
			const seasons = data.data;
			let season = null;
			if(seasons.length > 0)
				season = seasons[seasons.length - 1];

			const selectedSeason = localStorage.getItem('adminSeason');
			const filter = seasons.filter(x => x.id === Number(selectedSeason));
			if(filter.length === 1)
				season = filter[0];

			this.setState({
				seasons: seasons,
				season: season
			});
		}
	}
	async retrieveTeams() {
		const data = await FetchData("team");
		if(data.isError) {
			if(data.code === 404)
				return;
			this.props.setErrorMessage(data.message);
			return;
		}
		else {
			this.setState({
				teams: SortData(data.data, "name")
			});
		}
	}
	async showList(type) {
		let array;
		switch(type) {
			case "matches":
				array = this.state.matches;
				if(array.length === 0)
					await this.retrieveMatches();
				break;
			case "competitions":
				array = this.state.competitions;
				if(array.length === 0)
					await this.retrieveCompetitions();
				if(this.state.teams.length === 0)
					this.retrieveTeams();
				if(this.state.codes.length === 0)
					this.retrieveCodes();
				break;
			case "seasons":
				array = this.state.seasons;
				if(array.length === 0)
					await this.retrieveSeasons();
				break;
			case "teams":
				array = this.state.teams;
				if(array.length === 0)
					await this.retrieveTeams();
				break;
			case "players":
				array = this.state.players;
				if(array.length === 0)
					await this.retrievePlayers();
				break;
			default:
				console.log("Unknown type: " + type);
		}

		this.setState({
			list: type,
			data: null,
			addItem: null
		});
	}
	showData(data) {
		this.setState({
			data: data,
			addItem: null
		});
	}
	jumpToData(type, data) {
		this.setState({
			list: type,
			data: data,
			addItem: null
		});

		if(type === "matches") {
			if(data.competitions.length > 0) {
				const filter = this.state.competitions.filter(c => c.id === data.competitions[0].id);
				if(filter.length > 0) {
					this.setState({
						season: filter[0].season
					});
				}
			}
		}

		const scrollToData = () => {
			if(this.ref.current)
				this.ref.current.scrollIntoView({behavior: "smooth", block: "center"});
		};

		setTimeout(() => scrollToData(), 0);
	}
	renderMatches() {
		const matches = this.state.matches;
		const finished = [];
		const notFinished = [];
		for(let i = 0; i < matches.length; i++) {
			const match = matches[i];
			if(match.home_team_score !== null)
				notFinished.push(match);
			else
				finished.push(match);
		}

		notFinished.reverse();
		const sortedMatches = finished.concat(notFinished);
		
		const output = [];
		for(let i = 0; i < sortedMatches.length; i++) {
			const match = sortedMatches[i];
			if(match.competitions.length > 0) {
				const filter = this.state.competitions.filter(c => c.id === match.competitions[0].id);
				if(filter.length > 0)
					if(filter[0].season.id !== this.state.season.id)
						continue;
			}

			let className = "Item";
			let refId = false;
			if(this.state.data) {
				if(this.state.data.id === match.id) {
					className += " Selected";
					refId = true;
				}
			}

			let scores = "";
			if(match.home_team_score !== null) {
				className += " Finished";
				scores = ` (${match.home_team_score} - ${match.out_team_score})`;
			}

			output.push(
				<div
					key={match.id}
					className={className}
					onClick={() => this.showData(match)}
					ref={(refId ? this.ref : null)}
				>
					{`${match.home_team.name} - ${match.out_team.name}${scores}`}<br />
					{FormatTime(match.start_datetime).fancy}
				</div>
			);
		}

		return output;
	}
	renderCompetitions() {
		const competitions = this.state.competitions;
		const output = [];
		for(let i = 0; i < competitions.length; i++) {
			const competition = competitions[i];
			if(competition.season.id !== this.state.season.id)
				continue;

			let className = "Item";
			if(this.state.data)
				if(this.state.data.id === competition.id)
					className += " Selected";

			output.push(
				<div
					key={competition.id}
					className={className}
					onClick={() => this.showData(competition)}
				>
					{competition.name}
				</div>
			);
		}

		return output;
	}
	renderSeapons() {
		const seasons = this.state.seasons;
		const output = [];
		for(let i = 0; i < seasons.length; i++) {
			const season = seasons[i];
			let className = "Item";
			if(this.state.data)
				if(this.state.data.id === season.id)
					className += " Selected";

			output.push(
				<div
					key={season.id}
					className={className}
					onClick={() => this.showData(season)}
				>
					{season.name}
				</div>
			);
		}

		return output;
	}
	renderTeams() {
		const teams = this.state.teams;
		const output = [];
		for(let i = 0; i < teams.length; i++) {
			const team = teams[i];
			let className = "Item";
			if(this.state.data)
				if(this.state.data.id === team.id)
					className += " Selected";

			output.push(
				<div
					key={team.id}
					className={className}
					onClick={() => this.showData(team)}
				>
					{team.name}
				</div>
			);
		}

		return output;
	}
	renderPlayers() {
		const players = this.state.players;
		const output = [];
		for(let i = 0; i < players.length; i++) {
			const player = players[i];
			let className = "Item";
			if(this.state.data)
				if(this.state.data.id === player.id)
					className += " Selected";

			output.push(
				<div
					key={player.id}
					className={className}
					onClick={() => this.showData(player)}
				>
					{player.name}
				</div>
			);
		}

		return output;
	}
	renderMatchData(match) {
		return (
			<div key={match.id}>
				<AddScores
					updateData={this.updateData.bind(this)}
					setErrorMessage={this.props.setErrorMessage}
					match={match}
				/>
				<MenuMatch
					updateData={this.updateData.bind(this)}
					deleteData={this.deleteData.bind(this)}
					setErrorMessage={this.props.setErrorMessage}
					competitions={this.state.competitions.filter(c => c.season.id === this.state.season.id)}
					match={match}
				/>
			</div>
		)
	}
	renderCompetitionData(competition) {
		return (
			<div key={`${competition.id}_${Date.now()}`}>
				<MenuCompetition
					updateData={this.updateData.bind(this)}
					deleteData={this.deleteData.bind(this)}
					setErrorMessage={this.props.setErrorMessage}
					season={this.state.season}
					teams={this.state.teams}
					competition={competition}
				/>
				<MenuCodes
					updateData={this.updateData.bind(this)}
					deleteData={this.deleteData.bind(this)}
					setErrorMessage={this.props.setErrorMessage}
					competition={competition}
					codes={this.state.codes.filter(c => c.competition_id === competition.id)}
				/>
			</div>
		);
	}
	renderSeasonData(season) {
		return (
			<MenuSeason
				key={season.id}
				updateData={this.updateData.bind(this)}
				deleteData={this.deleteData.bind(this)}
				setErrorMessage={this.props.setErrorMessage}
				selectSeason={this.selectSeason.bind(this)}
				season={season}
			/>
		);
	}
	renderTeamData(team) {
		return (
			<MenuTeam
				key={team.id}
				updateData={this.updateData.bind(this)}
				deleteData={this.deleteData.bind(this)}
				setErrorMessage={this.props.setErrorMessage}
				competitions={this.state.competitions.filter(c => c.season.id === this.state.season.id)}
				teams={this.state.teams}
				team={team}
			/>
		);
	}
	renderPlayerData(player) {
		return (
			<MenuPlayer
				key={player.id}
				updateData={this.updateData.bind(this)}
				deleteData={this.deleteData.bind(this)}
				setErrorMessage={this.props.setErrorMessage}
				competitions={this.state.competitions.filter(c => c.season.id === this.state.season.id)}
				user={player}
			/>
		);
	}
	renderPlayedMatches() {
		const matches = this.state.matches.filter(match => match.start_datetime < Math.floor(Date.now() / 1000) && match.home_team_score == null);
		const sortedMatches = SortData(matches, 'start_datetime');
		const output = [];
		for(let i = 0; i < sortedMatches.length; i++) {
			const match = sortedMatches[i];
			output.push(
				<div
					key={match.id}
					className="Item"
					onClick={() => this.jumpToData("matches", match)}
				>
					{match.home_team.name} - {match.out_team.name}<br />
					{FormatTime(match.start_datetime).fancy}
				</div>
			);
		}

		return output;
	}
	render() {
		if(this.state.redirect)
			return (<Navigate to={"/admin" + this.state.redirect} />);

		const addMenu = this.state.addMenu;

		let list;
		let data;
		switch(this.state.list) {
			case "matches":
				list = this.renderMatches();
				if(this.state.data)
					data = this.renderMatchData(this.state.data);
				break;
			case "competitions":
				list = this.renderCompetitions();
				if(this.state.data) {
					data = this.renderCompetitionData(this.state.data);
					if(this.state.teams.length === 0)
						this.retrieveTeams();
				}
				break;
			case "seasons":
				list = this.renderSeapons();
				if(this.state.data)
					data = this.renderSeasonData(this.state.data);
				break;
			case "teams":
				list = this.renderTeams();
				if(this.state.data)
					data = this.renderTeamData(this.state.data);
				break;
			case "players":
				list = this.renderPlayers();
				if(this.state.data)
					data = this.renderPlayerData(this.state.data);
				break;
			default:
		}

		return (
			<div className="AdminPage">
				{addMenu ? <PopUp children={addMenu} /> : null}
				<h1>{this.state.season ? this.state.season.name : null}</h1>
				<div
					className="Column-wrapper"
					style={!this.state.season ? {marginTop: "76px"} : null}
				>
					<div className="Column Menu">
						<div className="Block">
							<h1>Menu</h1>
							<div className="List">
								<div
									className={this.state.list === "seasons" ? "Item Selected" : "Item"}
									onClick={() => this.showList("seasons")}
								>
									Seizoenen
								</div>
								<div
									className={this.state.list === "competitions" ? "Item Selected" : "Item"}
									onClick={() => this.showList("competitions")}
								>
									Competities
								</div>
								<div
									className={this.state.list === "matches" ? "Item Selected" : "Item"}
									onClick={() => this.showList("matches")}
								>
									Wedstrijden
								</div>
								<div
									className={this.state.list === "teams" ? "Item Selected" : "Item"}
									onClick={() => this.showList("teams")}
								>
									Teams
								</div>
								<div
									className={this.state.list === "players" ? "Item Selected" : "Item"}
									onClick={() => this.showList("players")}
								>
									Spelers
								</div>
							</div>
						</div>
						<div className="Block">
							<h1>Eindstand invullen</h1>
							<div className="List">
								{this.renderPlayedMatches()}
							</div>
						</div>
					</div>
					<div className="Column List">
						<div className="Block">
							<div className="List">
								{list}
							</div>
							{this.state.list && this.state.list !== "players" ? <ButtonAdd onClick={() => this.addItem(this.state.list)} /> : null}
						</div>
					</div>
					<div className="Column Data">
						<div className="Block">
							<div className="List">
								{data}
								{this.state.addItem}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	updateData(type, update) {
		let data = this.state[type];
		let found = false;
		for(let i = 0; i < data.length; i++) {
			const item = data[i];
			if(item.id === update.id) {
				data[i] = update;
				found = true;
				break;
			}
		}

		if(found)
			this.props.setErrorMessage("saved");

		if(!found) {
			data.push(update);
			switch(type) {
				case "matches":
					data = SortData(data, "start_datetime");
					break;
				case "codes":
					data = SortData(data, "expire_datetime");
					break;
				case "seasons":
					this.selectSeason(update);
					break;
				case "teams":
					this.retrieveCompetitions();
					data = SortData(data, "name");
					break;
				default:
					data = SortData(data, "name");
			}
		}
		else if(type === "seasons" && update.id === this.state.season.id)
			this.selectSeason(update);

		if(type === "codes") {
			this.setState({
				[type]: data
			});
		}
		else {
			this.setState({
				[type]: data,
				data: update,
				addItem: null
			});
		}
	}
	deleteData(type, item) {
		let data = this.state[type];
		data.splice(data.indexOf(item), 1);

		if(type === "codes") {
			this.setState({
				[type]: data
			});
		}
		else {
			this.setState({
				[type]: data,
				data: null
			});
		}

		if(type === "seasons" && item.id === this.state.season.id) {
			if(data.length > 0)
				this.selectSeason(data[data.length - 1]);
			else
				this.selectSeason(null);
		}
		else if(type === "teams")
			this.retrieveCompetitions();
	}
	async addItem(type) {
		if(!this.state.season && type !== "seasons") {
			this.setState({
				list: "seasons",
				data: null,
			});
			this.addItem("seasons");
			return;
		}

		switch(type) {
			case "matches":
				if(this.state.competitions.length === 0) {
					this.setState({
						list: "competitions",
						data: null,
					});
					this.addItem("competitions");
					return;
				}

				this.setState({
					addItem: <MenuMatch
						updateData={this.updateData.bind(this)}
						setErrorMessage={this.props.setErrorMessage}
						competitions={this.state.competitions.filter(c => c.season.id === this.state.season.id)}
					/>
				});
				break;
			case "competitions":
				this.setState({
					addItem: <MenuCompetition
						updateData={this.updateData.bind(this)}
						setErrorMessage={this.props.setErrorMessage}
						season={this.state.season}
						teams={this.state.teams}
					/>
				});
				break;
			case "seasons":
				this.setState({
					addItem: <MenuSeason
						updateData={this.updateData.bind(this)}
						deleteData={this.deleteData.bind(this)}
						setErrorMessage={this.props.setErrorMessage}
					/>
				});
				break;
			case "teams":
				if(this.state.competitions.length === 0) {
					this.setState({
						list: "competitions",
						data: null,
					});
					this.addItem("competitions");
					this.retrieveCompetitions();
					return;
				}

				this.setState({
					addItem: <MenuTeam
						updateData={this.updateData.bind(this)}
						setErrorMessage={this.props.setErrorMessage}
						competitions={this.state.competitions.filter(c => c.season.id === this.state.season.id)}
						teams={this.state.teams}
					/>
				});
				break;
			default:
				console.log("Unknown type: " + type);
		}

		this.setState({
			data: null
		});
	}
	selectSeason(season) {
		localStorage.setItem('adminSeason', season.id);
		this.setState({
			season: season
		});
	}
	redirect(page) {
		this.setState({
			redirect: page
		});
	}
}

export default AdminPage;