import React from "react";
import {Navigate} from "react-router-dom"
import './CompetitionPage.css';
import FetchData from '../../components/FetchData';
import SortData, {SortData2} from '../../components/SortData';
import CalculateScoreGained from '../../components/CalculateScoreGained';
import MatchCard from '../../components/MatchCard';
import CollapsibleMenu from '../../components/CollapsibleMenu';
import {ImageUrl} from "../../ApiUrl";

class CompetitionPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			competition: {name: ""},
			matchData: null,
			matches: [],
			players: [],
			leaderboard: [],
			filter: 0,
		};
	}
	async componentDidMount() {
		const split = this.props.location.split("/");
		this.competition = split[2];
		if(isNaN(parseInt(this.competition)))
			this.redirect = "/";
		else
			this.retrieveCompetition();
	}
	async retrieveCompetition() {
		const options = {
			method: "GET",
			headers: {"Authorization": "Bearer " + sessionStorage.getItem("token")},
		};

		let data = await FetchData("userapi/competition/" + this.competition, options);
		if(data.isError)
			this.props.setErrorMessage(data.message);
		else {
			const competition = data.data[0];
			const scoresMap = new Map();
			data = await FetchData(`userapi/matchscore/?from_competition=${competition.id}&user_only=true`, options);

			if(data.isError && data.code !== 404)
				this.props.setErrorMessage(data.message);
			else if(data.code !== 404) {
				data.data.forEach(score => {
					const matchScore = {
						id: score.id,
						home_team_score: score.home_team_score,
						out_team_score: score.out_team_score
					};

					scoresMap.set(score.match.id, matchScore);
				});

				competition.matches.forEach(match => {
					let team = match.home_team.id;
					match.home_team = competition.teams.filter(t => t.id === team)[0];
					team = match.out_team.id;
					match.out_team = competition.teams.filter(t => t.id === team)[0];

					match.matchscore = scoresMap.get(match.id);
					match.matchscores = [];
				});
			}

			const matchesSorted = SortData(competition.matches, "start_datetime");
			const matchesPlayed = [];
			const matchesNotPlayed = [];
			matchesSorted.forEach(match => {
				if(match.home_team_score == null )
					matchesNotPlayed.push(match);
				else
					matchesPlayed.push(match);
			});
			
			const matches = matchesNotPlayed.concat(matchesPlayed);

			this.setState({
				competition: competition,
				matches: matches,
				players: SortData(competition.users, "name"),
				leaderboard: SortData2(competition.users, "points", "name", true)
			});
		}
	}
	async retrieveMatch(id) {
		const m = this.state.matches.filter(match => match.id === id)[0];
		if(m.competition_id)
			return m;

		const options = {
			method: "GET",
			headers: {"Authorization": "Bearer " + sessionStorage.getItem("token")},
		};

		const data = await FetchData(`userapi/match/?competition_id=${this.competition}&id=${id}`, options);
		if(data.isError)
			this.props.setErrorMessage(data.message);
		else {
			const match = data.data[0];
			const matches = this.state.matches;
			for(let i = 0; i < matches.length; i++) {
				if(matches[i].id === id) {
					matches[i].matchscores = match.matchscores;
					break;
				}
			}

			match.leaderboard = SortData2(match.leaderboard, "points", "name", true);
			this.setState({
				matches: matches
			});
			return match;
		}
	}
	setMatchScores(match) {
		const players = this.state.players;
		players.forEach(player => {
			const matchscores = match.matchscores.filter(m => m.user.id === player.id);
			if(matchscores.length > 0) {
				player.matchscore = {
					home_team_score: matchscores[0].home_team_score,
					out_team_score: matchscores[0].out_team_score
				};
			}
			else
				player.matchscore = undefined;
		});
	}
	showList() {
		this.setState({
			match: null
		});
	}
	async showData(event, id) {
		if((event.target.localName === "input" || event.target.localName === "button") && !event.target.disabled)
			return;

		const match = await this.retrieveMatch(id);
		//this.getPreviousLeaderboard(match);

		this.setMatchScores(match);
		this.setState({
			matchData: match
		});
	}
	filterMatches(filter) {
		this.setState({
			filter: filter
		});
	}
	renderMatches() {
		const output = this.state.matches.map(match => {
			if(!match.home_team || !match.out_team)
				return null;

			if(this.state.filter === 0) {
				if(match.matchscore !== undefined)
					return null;

				if(match.start_datetime <= (Date.now() / 1000))
					return null;
			}
			else if(this.state.filter === 1 && match.home_team_score != null) {
				return null
			}
			else if(this.state.filter === 2 && match.home_team_score == null) {
				return null
			}

			const header = (
				<div onClick={(e) => this.showData(e, match.id)}>
					<MatchCard
						match={match}
						competition={this.state.competition}
						updateMatchscore={this.updateMatchscore.bind(this)}
					/>
				</div>
			);

			const content = (
				<div>{this.renderPlayerScores(match)}</div>
			);

			return (
				<CollapsibleMenu
					key={match.id}
					header={header}
					content={content}
					length={match.matchscores.length}
				/>
			);
		});

		if(this.state.filter === 2)
			output.reverse();

		return output;
	}
	renderPlayers() {
		const players = this.state.players;
		const output = [];
		for(let i = 0; i < players.length; i++) {
			const player = players[i];
			output.push(
				<div
					key={player.id}
					className="Item"
				>
					{player.name}
				</div>
			);
		}

		return output;
	}
	renderLeaderboard() {
		const buildLeaderboard = (leaderboard, matches) => {
			let rank = 1;
			const output = leaderboard.map(player => {
				/*let ranking;
				if(player.ranking) {
					if(player.ranking === 1)
						ranking = "/\\";
					else if(player.ranking === -1)
						ranking = "\\/";
				}*/

				const content = (
					<tr key={player.id}
						className="Item">
						<td className="Rank">
							{rank}.
						</td>
						<td className="Name">
							{player.name}
						</td>
						<td className="Points">
							{player.points}
						</td>
					</tr>
				);
				rank++;

				return content;
			});

			return output;
		}

		const output = (
			<table>
				<thead>
					<tr>
						<td className="Rank">Nr.</td>
						<td className="Name">Naam</td>
						<td className="Points">Punten</td>
					</tr>
				</thead>
				<tbody>
					{buildLeaderboard(this.state.leaderboard, this.state.competition.matches?.filter(x => x.home_team_score != null))}
				</tbody>
			</table>
		);

		return output;
	}
	renderPlayerScores(match) {
		const data = this.state.matchData;
		if(data === null)
			return;

		//Fixes collapsible menu when no one has entered a score
		if(match.matchscores.length === 0 && data.id === match.id) {
			match.matchscores.push({user: {id: 0}});
		}

		const output = this.state.players.map(player => {
			let score = "";
			let predicted = "";
			let scores;
			for(let i = 0; i < match.matchscores.length; i++) {
				const matchscores = match.matchscores[i];
				if(matchscores.user.id === player.id) {
					scores = matchscores;
					break;
				}
			}

			if(scores) {
				if(match.home_team_score !== null)
					score = ` (+${CalculateScoreGained(match.home_team_score, match.out_team_score, scores.home_team_score, scores.out_team_score)})`;

				predicted = scores.home_team_score + "-" + scores.out_team_score;
			}
			else if(match.home_team_score !== null)
				score = " (+0)";

			return (
				<tr
					key={player.id}
					className="Item"
				>
					<td className='Name'>{player.name}</td>
					<td>{predicted}</td>
					<td>{score}</td>
				</tr>
			);
		});

		return (
			<table cellSpacing='20'>
				<tbody>
					{output}
				</tbody>
			</table>
		);
	}
	render() {
		if(this.redirect)
			return (<Navigate to={this.redirect} replace={true} />);

		let pending = 0;
		let upcoming = 0;
		let played = 0;

		const pendingFilter = this.state.matches.filter(m => m.matchscore === undefined && m.start_datetime > (Date.now() / 1000));
		pending = pendingFilter.length;
		upcoming = this.state.matches.filter(m => m.home_team_score == null).length;
		played = this.state.matches.filter(m => m.home_team_score != null).length;

		let className = "";
		if(this.state.filter === 0)
			className = "Active ";
		else if(pendingFilter.length > 0)
			className += "Pending";

		return (
			<div className="CompetitionPage">
				<div className="Top">
					<div>
						{this.state.competition.competition_logo_file ?
							<img
								src={ImageUrl + this.state.competition.competition_logo_file}
								alt={this.state.competition.name}
							/> : null}
					</div>
					<h1>{this.state.competition.name}</h1>
				</div>
				<div className="Content">
					<div className="Column">
						<h2>WEDSTRIJDEN</h2>
						<div className="Filter">
							<button onClick={() => this.filterMatches(0)} className={className}>Nog invullen ({(pending)})</button>
							<button onClick={() => this.filterMatches(1)} className={(this.state.filter === 1 ? "Active" : "")}>Komend ({(upcoming)})</button>
							<button onClick={() => this.filterMatches(2)} className={(this.state.filter === 2 ? "Active" : "")}>Gespeeld ({(played)})</button>
						</div>
						{this.renderMatches()}
					</div>
					<div className="Column">
						<h2>RANGLIJST</h2>
						{this.renderLeaderboard()}
					</div>
				</div>
			</div>
		);
	}
	getPreviousMatch(currentMatch) {
		const index = this.state.matches.findIndex(match => match.id === currentMatch.id);
		if(index === 0)
			return;

		return this.state.matches[index - 1];
	}
	async getPreviousLeaderboard(currentMatch) {
		let match = this.getPreviousMatch(currentMatch);
		if(!match)
			return;

		if(match.leaderboard)
			return;

		this.retrieveMatch(match.id);
	}
	compareLeaderboard(previous, current) {
		for(let i = 0; i < current.length; i++) {
			const player = current[i];
			const index = previous.findIndex(p => p.id === player.id);
			if(index < i)
				player.ranking = -1;
			else if(index > i)
				player.ranking = 1;
			else
				player.ranking = 0;

			current[i] = player;
		}

		return current;
	}
	updateMatchscore(match, data) {
		const matches = this.state.matches;
		const index = matches.findIndex(m => m.id === match.id);
		match.matchscore = data
		matches[index] = match;

		this.setState({
			matches: matches
		});
	}
}

export default CompetitionPage;